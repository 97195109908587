import {createBehavior} from '@area17/a17-behaviors';

const formSelect = createBehavior('formSelect',
    {
        checkState() {
            const value = this.$node.options[this.$node.selectedIndex].value;

            if (!value || value === '') {
                this.$node.classList.remove('text-primary');
                this.$node.classList.add('text-placeholder');
            } else {
                this.$node.classList.add('text-primary');
                this.$node.classList.remove('text-placeholder');
            }
        },
    },
    {
        init() {
            this.$node.addEventListener('change', this.checkState, false);
            this.checkState();
        },
        destroy() {
            this.$node.removeEventListener('change', this.checkState);
        },
    },
);

export default formSelect;

