import { createBehavior } from '@area17/a17-behaviors';

const chamberFinder = createBehavior('chamberFinder',
    {
        hideChildren(parentListElement) {
            const expandedItems = parentListElement.querySelectorAll('.chamber-finder__content-expanded');
            expandedItems.forEach(item => {
                item.classList.add('hidden');
            });
        },

        showChildren(element) {
            const children = element.querySelectorAll('.chamber-finder__content-expanded');
            children.forEach(child => {
                child.classList.remove('hidden');
            });
        },

        contractAll() {
            const expandedItems = this.$node.querySelectorAll('.chamber-finder__chambers li.expanded');
            expandedItems.forEach(item => {
                item.classList.remove('expanded');
                this.hideChildren(item);
            });
        },

        makeItemsToggleable() {

            const contentItems = this.$node.querySelectorAll('.chamber-finder__content');

            if (!contentItems.length) {
                return;
            }

            contentItems.forEach(item => {
                item.addEventListener('click', (e) => {
                    if (e.currentTarget.classList.contains('single-chamber')) {
                        return;
                    }

                    const parentListElement = e.currentTarget.closest('li');
                    const alreadyExpanded = parentListElement.classList.contains('expanded');
                    const expandedItemExists = this.$node.querySelectorAll('li.expanded').length > 0;

                    if (alreadyExpanded) {
                        this.hideChildren(parentListElement);
                        parentListElement.classList.remove(('expanded'));
                    } else if (expandedItemExists) {
                        this.contractAll();
                        parentListElement.classList.add('expanded');
                        this.showChildren(parentListElement);
                    } else {
                        parentListElement.classList.add('expanded');
                        this.showChildren(parentListElement);
                    }
                });
            });
        },
    },
    {
        init() {
            this.makeItemsToggleable();
        },
        destroy() {
            this.$node.removeEventListener('click', this.log);
        }
    }
);

export default chamberFinder;

