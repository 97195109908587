import {createBehavior} from '@area17/a17-behaviors';

const stripeCustomElements = createBehavior('stripeCustomElements',
    {
        handleError(error) {
            const submitBtn = this.$node.querySelector('#submit');
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = ''; // Clear previous errors
            messageContainer.textContent = error.message || 'An unexpected error occurred. Please try again.';
            submitBtn.disabled = false;
        },
    },
    {
        async init() {
            const publicKeyResponse = await fetch(window.SITE_URL + 'connect/co-100/stripe/get-public-key', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({}),
            });

            const { publicKey } = await publicKeyResponse.json();

            if (!publicKey) {
                this.handleError('Something went wrong. Please try again.');
                return;
            }

            const stripe = Stripe(publicKey);
            const options = {
                mode: 'subscription',
                amount: 39900,
                currency: 'usd',
            };

            const elements = stripe.elements(options); //

            const paymentElementOptions = {
                business: {
                    name: 'CO— Digital Small Business Membership',
                },
                layout: {
                    type: 'accordion',
                    defaultCollapsed: false,
                    radios: false,
                    spacedAccordionItems: true,
                },
                paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
                terms: {
                    card: 'always',
                    googlePay: 'always',
                    applePay: 'always'
                }
            };
            const paymentElement = elements.create('payment', paymentElementOptions);
            paymentElement.mount('#payment-element');


            // add submit event listener to form then run the code below
            this.$node.addEventListener('submit', async (event) => {
                event.preventDefault();

                const email = document.getElementById('email').value;
                const priceId = document.getElementById('price_id').value;
                const fullName = document.getElementById('full_name').value;
                const submitBtn = this.$node.querySelector('#submit');
                // Prevent multiple form submissions
                if (submitBtn.disabled) {
                    return;
                }

                // Disable form submission while loading
                submitBtn.disabled = true;

                const {error: submitError} = await elements.submit();
                if (submitError) {
                    console.error("Validation failed:", submitError);
                    this.handleError(submitError);
                }
                try {
                    const response = await fetch('/connect/co-100/stripe/setup-intent', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({}),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const { clientSecret } = await response.json();

                    if (!clientSecret) {
                        throw new Error("Client secret missing from response");
                    }

                    // Create the ConfirmationToken using the details collected by the Payment Element
                    const {confirmationError, confirmationToken} = await stripe.createConfirmationToken({
                        elements,
                    });

                    if (confirmationError) {
                        // This point is only reached if there's an immediate error when
                        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
                        return;
                    }

                    const { setupIntent, error } = await stripe.confirmSetup({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: 'https://co-uschamber.ddev.site/connect/actions/cc-success',
                        },
                        redirect: "if_required"
                    });

                    if (error) {
                        console.error("Stripe confirmation error:", error.message);
                        return;
                    }

                    console.log("SetupIntent confirmed!", setupIntent);
                    const paymentMethodId = setupIntent.payment_method;

                    const customerResponse = await fetch('/connect/co-100/stripe/create-confirm-sub', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            email: email,
                            price_id: priceId,
                            full_name: fullName,
                            paymentMethodId: paymentMethodId,
                            confirmationTokenId: confirmationToken.id,
                        }),
                    });

                    const {customerId, subscriptionId, successUrl} = await customerResponse.json();
                    if (customerId && subscriptionId) {
                        console.log('Customer created:', customerId);
                        console.log('Subscription created:', subscriptionId);
                        window.location.href = successUrl;
                    }


                } catch (error) {
                    if (error) {
                        console.error("Error fetching client secret:", error);
                        this.handleError(error);
                    }
                }

            });
        },
    },
);

export default stripeCustomElements;