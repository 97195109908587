import { createBehavior } from '@area17/a17-behaviors';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';

const categoryFinder = createBehavior('categoryFinder',
    {},
    {
        init() {
            new Swiper('[category-swiper]', {
                direction: 'horizontal',
                loop: true,
                autoplay: false,

                pagination: {
                    el: '.category-swiper-pagination'
                },
                modules: [Navigation, Pagination, Autoplay],
            });

            // Dropdown toggle functionality
            const pickerToggle = document.getElementById('picker-toggle');
            const labelElement = document.querySelector('label[for="picker-toggle"]');
            const pickerMenu = document.querySelector('.picker-menu');
            const svgElement = labelElement.querySelector('svg');

            if (pickerToggle) {
                pickerToggle.addEventListener('change', () => {
                    if (pickerToggle.checked) {
                        svgElement.classList.add('rotate-180');
                    } else {
                        svgElement.classList.remove('rotate-180');
                    }
                });

                document.addEventListener('click', (event) => {
                    const isClickInside = pickerToggle.contains(event.target) || labelElement.contains(event.target)
                    || pickerMenu.contains(event.target);

                    if (!isClickInside) {
                        pickerToggle.checked = false;
                        svgElement.classList.remove('rotate-180'); // Reset rotation on collapse
                    }
                });
            }
        },
    }
);

export default categoryFinder;
