import {createBehavior} from '@area17/a17-behaviors';

const landingPage = createBehavior('landingPage',
    {
        getHeadings() {
            const h3s = this.$node.querySelectorAll('h3');
            const h4s = this.$node.querySelectorAll('h4');
            return [...h3s, ...h4s];
        },

        makeClickable() {
            this.headings.forEach(heading => {
                heading.addEventListener('click', () => {
                    if (heading.id) {
                        // make a url based off the current path with the id hashed at the end
                        const url = `${window.location.pathname}#${heading.id}`;

                        // update the url without refreshing the page
                        window.history.pushState(null, null, url);

                        // copy the url to the clipboard
                        navigator.clipboard.writeText(window.location.href);

                    }
                })
            });
        }
    },
    {
        init() {
            this.headings = this.getHeadings();
            this.makeClickable();
        },
    },
);

export default landingPage;
