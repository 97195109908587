import {createBehavior} from '@area17/a17-behaviors';

const formatPhone = createBehavior('formatPhone',
    {
        formatPhone(input) {
            // Strip all non-numeric characters
            let value = input.value.replace(/\D/g, '');

            // Limit to 10 digits
            value = value.substring(0, 10);

            // Format the number as xxx-xxx-xxxx
            if (value.length > 6) {
                value = value.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
            } else if (value.length > 3) {
                value = value.replace(/(\d{3})(\d{1,3})/, '$1-$2');
            }

            input.value = value;
        }

},
    {
        init() {
            this.$node.addEventListener('input', (event) => {
                this.formatPhone(event.target);
            });
        }
    }
);

export default formatPhone;
