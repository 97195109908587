import { createBehavior } from '@area17/a17-behaviors';

const accordion = createBehavior('accordion',
    {

    },
    {
        init() {
            const buttons = this.$node.querySelectorAll('button');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', () => {
                    const panel = buttons[i].nextElementSibling;
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + 'px';
                    }
                });
            }
        },
    }
);

export default accordion;
