import {createBehavior} from '@area17/a17-behaviors';
import 'core-js/stable';

const subscriptions = createBehavior('subscriptions',
    {
        addListeners() {
            const lightswitches = this.$node.querySelectorAll('input[type="checkbox"][name]');
            lightswitches.forEach(lightswitch => {
                lightswitch.addEventListener('change', () => {
                    this.updateSubscriptionStatus(lightswitch);
                    this.updateSubscriptions();
                });
            });
        },

        updateSubscriptionStatus(lightswitch) {
            let currentElement = lightswitch;

            while (currentElement && !currentElement.classList.contains('order-1')) {
                currentElement = currentElement.parentElement;
            }
            currentElement = currentElement.nextSibling;

            if (currentElement) {
                const subscribedSpan = currentElement.querySelector('.f-caption.text-legacy-grey');
                if (lightswitch.checked) {
                    subscribedSpan.classList.remove('hidden');
                } else {
                    subscribedSpan.classList.add('hidden');
                }
            }
        },

        async postDataAsJson(actionUrl, data) {
            const fetchOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: data,
            };

            const response = await fetch(actionUrl, fetchOptions);

            if (!response.ok) {
                const text = await response.text();
                console.error('Unexpected response:', text);
                throw new Error(`HTTP ${response.status}: ${text}`);
            }

            try {
                return await response.json();
            } catch (e) {
                console.error('Failed to parse JSON');
                throw new Error(`Invalid JSON response`);
            }
        },

        async updateSubscriptions() {
            const lightswitches = this.$node.querySelectorAll('input[type="checkbox"][name]');
            const topicPreferences = {};

            lightswitches.forEach(lightswitch => {
                topicPreferences[lightswitch.name] = lightswitch.checked;
            });

            try {
                return this.postDataAsJson(
                    window.SITE_URL + 'actions/members/subscription-preferences/update-subscription-preferences',
                    JSON.stringify(topicPreferences)
                )
            } catch (error) {
                console.error('Error updating subscriptions:', error);
            }
        },
    },
    {
        init() {
            this.addListeners();
        },
    },
);

export default subscriptions;
