
import { createBehavior } from '@area17/a17-behaviors';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

const autoplayOptions = {
    delay: 5000,
    disableOnInteraction: true
};

const peoplePanel = createBehavior('peoplePanel',
    {},
    {
        init() {
            console.log('swiper?');
            const swiperMobile = new Swiper('[data-people-mobile]', {
                // Optional parameters
                direction: 'horizontal',
                loop: true,
                autoplay: autoplayOptions,

                pagination: {
                    el: '.swiper-pagination'
                },

                // Navigation arrows
                navigation: {
                    nextEl: '[data-people-mobile] .swiper-button-next',
                    prevEl: '[data-people-mobile] .swiper-button-prev',
                },
                modules: [Navigation, Pagination, Autoplay],
            });

            const swiperDesktop = new Swiper('[data-people-desktop]', {
                // Optional parameters
                direction: 'horizontal',
                loop: true,
                autoplay: autoplayOptions,

                pagination: {
                    el: '.swiper-pagination'
                },

                // Navigation arrows
                navigation: {
                    nextEl: '[data-people-desktop] .swiper-button-next',
                    prevEl: '[data-people-desktop] .swiper-button-prev',
                },
                modules: [Navigation, Pagination, Autoplay],
            });
        },
    }
);

export default peoplePanel;
