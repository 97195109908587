import {createBehavior} from '@area17/a17-behaviors';

const formSelect = createBehavior('qualifyingQuestions',
    {
        checkForm() {
            const form = this.$node.getElementsByTagName("form")[0];
            const elements = Array.from(form.elements);
            const yesValues = elements.filter(e => e.labels?.[0].textContent === "Yes").map(e => e.checked);
            const totalChecked = elements.map(e => e.checked).filter(Boolean).length;
            const allTrue = yesValues.every(x => x === true);
            if (totalChecked < 5) {
                this.setVisibility(true, false, false);
            } else if (allTrue) {
                this.setVisibility(false, true, false);
            } else {
                this.setVisibility(false, false, true);
            }
        },
        setVisibility(def, pos, neg) {
            this.$node.getElementsByClassName("qualifying-questions-default")[0].style.display = def ? "flex" : "none";
            this.$node.getElementsByClassName("qualifying-questions-positive")[0].style.display = pos ? "flex" : "none";
            this.$node.getElementsByClassName("qualifying-questions-negative")[0].style.display = neg ? "flex" : "none";
        }
    },
    {
        init() {
            const form = this.$node.getElementsByTagName("form")[0];
            Array.from(form.elements).forEach(e => {
                e.addEventListener("change", this.checkForm);
            });
        },
        destroy() {
            const form = this.$node.getElementsByTagName("form")[0];
            Array.from(form.elements).forEach(e => {
                e.removeEventListener("change", this.checkForm);
            });
        },
    },
);

export default formSelect;

