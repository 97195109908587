import { createBehavior } from '@area17/a17-behaviors';

const gridToggle = createBehavior('gridToggle',
    {
        toggleState() {
            const toggle = this.$node.querySelector('.grid-toggle');

            if (toggle) {
                const label = this.$node.querySelector(`label[for="${toggle.id}"]`);
                const textDiv = label.querySelector('div');
                const svgIcon = label.querySelector('svg');

                    toggle.addEventListener('change', function () {
                        const isClicked = toggle.checked;

                        if (isClicked) {
                            textDiv.textContent = 'Show Less';
                            svgIcon.classList.add('rotate-180');
                        } else {
                            textDiv.textContent = 'All Categories';
                            svgIcon.classList.remove('rotate-180');
                        }});
                }},
    },
    {
        init() {
            this.toggleState();
        },
    }
);

export default gridToggle;
