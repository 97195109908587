import animations from './animations.js';
import modMover from './modMover.js';
import checkCmsPaths from './checkCmsPaths.js';
import { manageBehaviors } from '@area17/a17-behaviors';
import * as Behaviors from './behaviors';
import DOMReady from './helpers/DOMReady.js';
import cssConfig from '@css/app.config.json';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

checkCmsPaths.init();

DOMReady(async () => {
    animations.init();

    window.CO = window.CO || {};
    window.CO.modMover = modMover;
    window.CO.structure = cssConfig.structure;

    let breakpoints = [];
    if (cssConfig.structure && cssConfig.structure.breakpoints) {
        for (const [key, value] of Object.entries(cssConfig.structure.breakpoints)) {
            breakpoints.push({name: key, start: value});
        }
        breakpoints = breakpoints.sort(
            (a, b) => parseInt(a.start) - parseInt(b.start),
        );
        breakpoints = breakpoints.map((a) => a.name);
    }

    // Prepare breakpoints for @area17/a17-behaviors package
    window.A17 = window.A17 || {};
    window.A17.behaviors = manageBehaviors;
    window.A17.behaviors.init(Behaviors, {
        breakpoints: breakpoints
    });


    // Select all focusable elements
    const focusableElements = document.querySelectorAll('.gtm_nav_main');
    let currentIndex = -1;

    document.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            if (currentIndex === -1) {
                currentIndex = 0; // Set initial focus to the first element if not already focused
            } else {
                if (event.key === 'ArrowRight') {
                    currentIndex = (currentIndex + 1) % focusableElements.length;
                } else if (event.key === 'ArrowLeft') {
                    currentIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
                }
            }
            focusableElements[currentIndex].focus();
            event.preventDefault();
        }
    });

    document.body.classList.add('loaded');
    // fire custom event to let other scripts know app is loaded
    document.dispatchEvent(new Event('app:loaded'));
});

