const checkCmsPaths = {
    init: function() {
        // check for CMS and redirect
        if (window.location.href === 'https://co-staging.uschamber.com/') {
            window.location.pathname = '/cms';
        } else if (window.location.href === 'https://co-admin.uschamber.com/') {
            window.location.pathname = '/cms';
        }
    },
};

export default checkCmsPaths;