import { createBehavior } from '@area17/a17-behaviors';
const navigation = createBehavior('navigation',
    {

        toggleLogoVisibilityForAccessibility(toggled) {
            const lightMode = this.$node.classList.contains('light');
            const coBlue = '.navigation__co--blue';
            const coGold = '.navigation__co--gold';
            const usccBlue = '.navigation__chamber--blue';
            const usccWhite = '.navigation__chamber--white';
            if (!toggled && lightMode) {
                // CO Logo
                this.$node.querySelector(coGold)?.removeAttribute('aria-hidden');
                this.$node.querySelector(coGold)?.removeAttribute('tabindex');
                this.$node.querySelector(coBlue)?.setAttribute('aria-hidden', true);
                this.$node.querySelector(coBlue)?.setAttribute('tabindex', -1);
                // USCC Logo
                this.$node.querySelector(usccWhite)?.removeAttribute('aria-hidden');
                this.$node.querySelector(usccWhite)?.removeAttribute('tabindex');
                this.$node.querySelector(usccBlue)?.setAttribute('aria-hidden', true);
                this.$node.querySelector(usccBlue)?.setAttribute('tabindex', -1);
            } else if (lightMode) {
                // CO Logo
                this.$node.querySelector(coBlue)?.removeAttribute('aria-hidden');
                this.$node.querySelector(coBlue)?.removeAttribute('tabindex');
                this.$node.querySelector(coGold)?.setAttribute('aria-hidden', true);
                this.$node.querySelector(coGold)?.setAttribute('tabindex', -1);
                // USCC Logo
                this.$node.querySelector(usccBlue)?.removeAttribute('aria-hidden');
                this.$node.querySelector(usccBlue)?.removeAttribute('tabindex');
                this.$node.querySelector(usccWhite)?.setAttribute('aria-hidden', true);
                this.$node.querySelector(usccWhite)?.setAttribute('tabindex', -1);
            }
        },

        toggleExpandedMenuForAccessibility() {
            const isExpanded = this.$node.classList.contains('expanded');
            this.hamburger.setAttribute('aria-expanded', isExpanded);
            const expandedNavElements = this.$node.querySelectorAll('.navigation__footer, .navigation__sections-wrapper');
            for (let i = 0; i < expandedNavElements.length; i++) {
                expandedNavElements[i].setAttribute('aria-hidden', !isExpanded);
                expandedNavElements[i].setAttribute('aria-expanded', isExpanded);
                if (isExpanded) {
                    expandedNavElements[i].removeAttribute('tabindex');
                } else {
                    expandedNavElements[i].setAttribute('tabindex', -1);
                }
                // recursively set aria-hidden on all children
                const children = expandedNavElements[i].querySelectorAll('button, a');
                for (let j = 0; j < children.length; j++) {
                    children[j].setAttribute('aria-hidden', !isExpanded);
                    if (isExpanded) {
                        children[j].removeAttribute('tabindex');
                    } else {
                        children[j].setAttribute('tabindex', -1);
                    }
                }
            }
        },

        navEvents () {

            this.hamburger.addEventListener('click', (e) => {
                this.$node.classList.toggle('expanded');
                this.toggleExpandedMenuForAccessibility();
            });
        },
    },
    {
        init() {
            this.hamburger = this.$node.querySelector('.navigation__hamburger');

            this.navEvents();
            this.toggleLogoVisibilityForAccessibility();
            this.toggleExpandedMenuForAccessibility();
        }
    }
);

export default navigation;