import { createBehavior } from '@area17/a17-behaviors';
import Cookies from 'js-cookie'

const alertBar = createBehavior('alertBar',
    {
        verifyAlertShouldShow () {
            if (Cookies.get(this.cookieName)) {
                this.mainPage.classList.remove('alert-bar-enabled');
                return false;
            } else {
                return true;
            }
        },

        showAlert () {
            this.mainPage.classList.add('alert-bar-enabled');
        },

        addCloseEventListener () {

            this.getChild('close').addEventListener('click', () => {
                this.mainPage.classList.replace('alert-bar-enabled', 'alert-closed');
                let expiryDate = new Date();
                let time = expiryDate.getTime();
                time += 3600 * 1000;
                Cookies.set(this.cookieName, 'true', {
                    expires: expiryDate.setTime(time),
                    secure: true,
                    sameSite: 'Strict',
                });
            });
        },
    },
    {
        init() {
            this.cookieName = 'alertBar';
            const currentUrlArray = window.location.pathname.split('/');
            const currentSlug = currentUrlArray.pop();
            const alertLinkArray = this.getChild('link').href.split('/');
            const alertLinkSlug = alertLinkArray.pop();

            // kill alert bar if user is on the destination page
            if (alertLinkSlug === currentSlug) {
                return false;
            }

            this.mainPage = document.querySelector('.main');

            if (this.verifyAlertShouldShow()) {
                this.showAlert();
                this.addCloseEventListener();
            }
        },
    }
);

export default alertBar;
