import {createBehavior} from '@area17/a17-behaviors';

const gallery = createBehavior('gallery',
    {
        setArrowVisibility() {
            const galleryWidth = this.$node.clientWidth;
            const imagesWidth = this.$node.querySelector('[data-gallery-container]').scrollWidth;
            const arrowContainer = document.querySelector('[arrow-container]');
            if (imagesWidth - 95 > galleryWidth) {
                arrowContainer.classList.add('md:flex');
            } else {
                arrowContainer.classList.remove('md:flex');
            }
        }
    },
    {
        init() {
            const div = this.getChild('container');
            if (div) {
                div.scrollLeft = (div.scrollWidth - div.clientWidth) / 2;
            }
            this.$node.querySelector('[arrow-left]').addEventListener('click', ()=> {
                document.querySelector('[data-gallery-container]').scrollBy({left: -340, behavior: 'smooth'})
            });
            this.$node.querySelector('[arrow-right]').addEventListener('click', ()=> {
                document.querySelector('[data-gallery-container]').scrollBy({left: 340, behavior: 'smooth'})
            });
            window.addEventListener('resize', ()=> this.setArrowVisibility());
            this.setArrowVisibility();
        }
    }
);

export default gallery;
