import { createBehavior } from '@area17/a17-behaviors';
import Cookies from 'js-cookie'

const cookieBar = createBehavior('cookieBar',
    {
        showCookiePane() {
            this.$node.classList.add('is-shown');
            setTimeout(() => {
                this.$node.classList.add('is-visible');
            }, 350);
        },

        hideCookiePane() {
            this.$node.classList.remove('is-visible');
            setTimeout(() => {
                this.$node.classList.remove('is-shown');
            }, 350);

            this.$node.setAttribute('style', 'display:none;');
        },
    },
    {
        init() {
            this.cookieName = 'uscc-co-okie';

            if ((!document.cookie && document.cookie !== '')) {
                return;
            }

            const button = this.$node.querySelector('.js-cookie-btn');

            const isFirstTime = Cookies.get(this.cookieName) === undefined;

            if (isFirstTime) {
                this.showCookiePane();
            } else {
                this.hideCookiePane();
            }

            button.addEventListener('click', e => {
                e.preventDefault();

                Cookies.set(this.cookieName, 'true', {
                    expires: 365,
                    secure: true,
                    sameSite: 'Strict',
                    path: '/'
                });
                this.hideCookiePane();
            });
        }
    }
);

export default cookieBar;