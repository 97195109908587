
const animations = {
    init: function() {
        this.animationEvents();
    },

    animationEvents: function() {
        const checkTop = this.checkTop;
        const checkMid = this.checkMid;
        const animate = document.querySelectorAll('.animate');

        if (animate.length) {
            document.addEventListener('DOMContentLoaded', function() {
                checkTop();
            });

            window.addEventListener('scroll', function() {
                checkTop();
            });

        }

        const animateSlow = document.querySelectorAll('.animate-slow');
        if (animateSlow.length) {
            document.addEventListener('DOMContentLoaded', function() {
                checkMid();
            });

            window.addEventListener('scroll', function() {
                checkMid();
            });
        }

        const toggleOpen = document.querySelectorAll('.toggle-open');

        if (toggleOpen.length) {
            toggleOpen.forEach((element) => {
                element.addEventListener('click', function() {
                    element.classList.toggle('open');
                });
            });
        }
    },

    checkTop: function() {
        const top = window.pageYOffset;
        const bottom = top + window.innerHeight;

        const animatedElements = document.querySelectorAll('.animate');
        animatedElements.forEach((element) => {
            const elementTop = element.offsetTop;

            if (elementTop <= bottom) {
                element.classList.add('in-view');
            }
        });
    },

    checkMid: function() {
        const top = window.pageYOffset;
        const bottom = top + (window.innerHeight * 0.25);

        const slowAnimatedElements = document.querySelectorAll('.animate-slow');
        slowAnimatedElements.forEach(element => {
            const elementTop = element.offsetTop;

            if (elementTop <= bottom) {
                element.classList.add('in-view');
            }
        });
    },
};

export default animations;