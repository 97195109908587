import {createBehavior} from '@area17/a17-behaviors';

const keyDates = createBehavior('keyDates',
    {
        addRowStyles(row, prevRow) {
            const currentDate = new Date();
            const dateEl = row.querySelector('[data-raw-date]');
            const date = new Date(dateEl.dataset.rawDate * 1000);
            const isPast = date < currentDate;
            if (isPast) {
                row.classList.add('text-[var(--grey-100)]');
                return true;
            } else {
                if (prevRow && !prevRow.dataset.isCurrent) {
                    row.classList.add('text-[var(--orange-premium)]');
                    const arrow = this.$node.querySelector('[data-arrow-template]');
                    row.querySelector('[data-arrow-location]').appendChild(arrow.content.cloneNode(true));
                }
                row.dataset.isCurrent = true;
            }
        }
    },
    {
        init() {
            const rows = this.getChildren('row');
            let prevRow = null;
            let allDatesInPast = true;
            rows.forEach((row) => {
                const isInPast = this.addRowStyles(row, prevRow);
                if (!isInPast) {
                    allDatesInPast = false;
                }
                prevRow = row;
            });
            if (allDatesInPast) {
                const overdue = this.$node.querySelector('[data-overdue]');
                // clear out the contents of this.$node
                while (this.$node.firstChild) {
                    this.$node.removeChild(this.$node.firstChild);
                }
                this.$node.appendChild(overdue.content.cloneNode(true));

            }
        }
    }
);

export default keyDates;
