const modMover = {
    // not handling the newsletter elements
    init: function(modSelector, destinationElementsSelector, startIndex, shouldClone) {
        const isLandingPage = destinationElementsSelector === '.main__wrapper';
        const destinationElements = document.querySelectorAll(`${destinationElementsSelector} > *`);
        const movingElement = document.querySelector(modSelector);

        const destinationElement = this.getDestinationElement(destinationElements, startIndex, isLandingPage);
        if (movingElement != null) {

            if (movingElement.dataset.modType === 'product-engagement-mod') {
                if (shouldClone) {
                    this.cloneAndInsert(destinationElement, movingElement);
                } else {
                    this.insertAfter(destinationElement, movingElement);
                }
            } else {
                this.insertAfter(destinationElement, movingElement);
            }
        }
    },

    insertAfter: function(destinationElement, movingElement) {
        if (movingElement.dataset.modType === 'product-engagement-mod') {
            movingElement.dataset.trackingName += ' - PEM-TOP';
        }
        destinationElement.parentNode.insertBefore(movingElement, destinationElement.nextSibling);
    },

    cloneAndInsert: function(destinationElement, movingElement) {

        let clone = movingElement.cloneNode(true);

        movingElement.dataset.trackingName += ' - PEM-BOTTOM';

        movingElement.getElementsByClassName('gtm_pem_click')[0].dataset.clickTracking += ' - PEM-BOTTOM';

        clone.dataset.trackingName += ' - PEM-TOP';

        clone.getElementsByClassName('gtm_pem_click')[0].dataset.clickTracking += ' - PEM-TOP';

        // Move Cloned Element to Assigned Space
        destinationElement.parentNode.insertBefore(clone, destinationElement.nextSibling);
    },

    /**
     *
     * @param destinationElements array of elements on page
     * @param startingIndex index to start looking for double paragraph elements
     * @param isLandingPage boolean to determine if we are on the landing page
     */
    getDestinationElement: function(destinationElements = [], startingIndex = 5, isLandingPage = false) {
        if (destinationElements.length < startingIndex) {
            startingIndex = Math.ceil(destinationElements.length / 2);
        }

        if (isLandingPage) {
            const dataComponents = document.querySelectorAll('.main__wrapper > [data-component]');
            return this.findLandingPageLocation(destinationElements, dataComponents, startingIndex);
        } else {
            return this.findAcceptableLocation(destinationElements, startingIndex);
        }
    },


    findAcceptableLocation: function(elements, index, isLandingPage = false) {

        const maxLength = elements.length - 1;
        if (index >= maxLength) {
            return elements[maxLength];
        }

        if (this.locationAcceptable(elements[index], elements[index + 1], isLandingPage)) {
            return elements[index];
        } else {
            return this.findAcceptableLocation(elements, index + 1, isLandingPage);
        }
    },

    findLandingPageLocation: function(elements, dataComponentElements, startingIndex = 0) {
        if (dataComponentElements.length > 1) {
            return dataComponentElements[0];
        }

        return this.findAcceptableLocation(elements, startingIndex + 1, true);

    },

    locationAcceptable: function(element1, element2, isLandingPage) {
        if (isLandingPage) {
            return this.locationAcceptableLandingPage(element1, element2);
        }

        if (element1.tagName === 'P' && element2.tagName === 'P') {
            return !element2.querySelector('strong');

        }
        return element1.tagName === 'P' && element2.tagName === 'H2';

    },

    locationAcceptableLandingPage: function(element1, element2) {
        const allowedTags = ['DIV', 'SPAN'];

        if (element1.tagName === 'DIV' && element2.tagName === 'DIV') {
            return !element2.classList.contains('special-breaker');
        }

        // return boolean if element 1 and element 2 are in the allowed tags array
        return allowedTags.includes(element1.tagName) && allowedTags.includes(element2.tagName);


    },

    //   Use this file to add js for to move other elements
};

export default modMover;