import {createBehavior} from '@area17/a17-behaviors';

import instantsearch from 'instantsearch.js';
import algoliasearch from 'algoliasearch/lite';
import {connectSearchBox} from 'instantsearch.js/es/connectors';


import {configure, hits, refinementList, stats, clearRefinements} from 'instantsearch.js/es/widgets';
import {createDropdown} from '../helpers/algoliaInstantSearchDropdown';


const awardSearch = createBehavior('awardSearch',
    {
        createBaseHTML() {

            this.$node.innerHTML = `
            <style>
                form.ais-SearchBox-form {
                    box-sizing: border-box;
                    width: 100%;
                    position:relative;
                }

                input.ais-SearchBox-input, #DateRanges input[type="date"] {
                  border: 1px solid #0B0E2D;
                  padding: 10px 20px 8px;
                  border-radius: 4px;
                  font-size: 16px;
                  cursor: pointer;
                  background-color: transparent;
                  width: 100%;
                  color: #475569;
                  text-align: left;
                }
                ::-ms-clear{display:none;}
                
                button.ais-SearchBox-submit {
                    position: absolute;
                    z-index: 10;
                    right: .5rem;
                    top: 1.1rem;
                }
                button.ais-SearchBox-reset {
                    position: absolute;
                    z-index: 10;
                    right: 2rem;
                    top: 1.1rem;
                }

                svg.ais-SearchBox-submitIcon {
                    height: 15px;
                    width: 15px;
                }

                /* DROPDOWN STYLING */
                .ais-Dropdown-button {
                  border: 1px solid #0B0E2D;
                  padding: 10px 20px 8px;
                  border-radius: 4px;
                  font-size: 16px;
                  cursor: pointer;
                  background-color: transparent;
                  width: 100%;
                  color: #475569;
                  text-align: center;
                }
                .ais-Dropdown-button--refined {
                  font-weight: bold;
                }

                .ais-Dropdown .ais-Panel-body > div > div[class^="ais-"] {             
                    position: absolute;
                    border-radius: 4px;
                    background-color: #fff;
                    margin-top: 12px;
                    z-index: 10;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }
                
                .ais-Dropdown .ais-Panel-header {
                  margin-bottom: 0;
                  padding-bottom: 0;
                  border-bottom: 0;
                }

                .ais-Dropdown .ais-Panel-body {
                  display: none;
                }

                .ais-Dropdown--opened .ais-Panel-body {
                  display: block;
                }

                .ais-Dropdown .ais-Panel-body {
                  position: relative;
                }

                .ais-Dropdown-close {
                  display: none;
                }
                
                .ais-RefinementList-list {
                    font-size: 14px;
                }
                
                .ais-RefinementList-item {
                    display:flex;
                    padding: 8px 12px;
                    min-width: 200px;
                }
                
                .ais-RefinementList-item:hover {
                    background: #EEEEEE;
                }
                
                .ais-RefinementList-item.ais-RefinementList-item--selected {
                    font-weight: bolder;
                    color: #fff;
                    background: #1C3363;
                }
                
                .ais-Dropdown-button:hover {
                    background: #eee;
                }
                
                .ais-Dropdown.ais-Dropdown--opened {
                    background: #ddd;
                }

                /* mobile */
                @media only screen and (max-width: 375px) {
                  body {
                    overflow-x: hidden;
                  }

                  .ais-Dropdown--opened .ais-Panel-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #fff;
                  }

                  .ais-Dropdown--opened .ais-Panel-body > div > div[class^="ais-"] {
                    margin-top: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 85vh;
                    border: 0;
                  }

                  .ais-Dropdown--opened .ais-Panel-footer {
                    position: absolute;
                    top: 85vh;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                  }

                  .ais-Dropdown-close {
                    border: 1px solid #d6d6e7;
                    background: #fff;
                    border-radius: 0.25rem;
                    padding: 0.25rem 0.5rem;
                    cursor: pointer;
                    width: calc(100% - 2rem);
                    margin: 0 1rem;
                    height: 3rem;
                    font-size: 1rem;
                  }

                  .ais-Dropdown-close:hover {
                    background-color: #f5f5fa;
                  }

                  .ais-Dropdown--opened .ais-Dropdown-close {
                    display: inline;
                  }
              }

                /* Custom Refinement for dropdown */
                .ais-RefinementList-labelText {
                   margin-left: 8px;
                }
                .ais-RefinementList-count::before {
                   content: "(";
                }
                .ais-RefinementList-count::after {
                   content: ")";
                }
                .ais-RefinementList-count {
                   margin-left: 4px;
                }
                .ais-RefinementList-item.ais-RefinementList-item--selected {
                   font-weight: bold;
                }

                /* Pagination */
                .ais-Pagination-list {
                    display: flex;
                    justify-content: center;
                    margin-top: 1rem;
                }
                .ais-Pagination-item {
                    margin: 0 0.5rem;
                }
                .ais-Pagination-item a {
                    padding: 0.5rem 1rem;
                    border-radius: 0.25rem;
                    border: 1px solid #d6d6e7;
                    color: var(--txt-primary-force);
                    font-size: 0.8rem;
                }
                .ais-Pagination-item a:hover {
                    background-color: #f5f5fa;
                }
                .ais-Pagination-item.ais-Pagination-item--selected a {
                    font-weight: bold;
                }

                /* Hierarchy */
                .ais-HierarchicalMenu-list--child {
                    margin-left: 1rem;
                }

                .ais-SearchBox-input::-webkit-search-cancel-button{
                    display:none;
                }


                .ais-ClearRefinements-button:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                }
            </style>
            <div class="flex flex-col">
                <section class="container-escape">
                    <div class="container mt-32 ">
                        <div id="Filters" class="w-full grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-24" ></div>
                    <div class="flex flex-row items-center mt-32">
                        <div id="HitStats" class="mr-16" ></div>
                        <hr class="flex-grow border-gray-400" />
                    </div>
                    </div>
                </section>
                <div class="w-full">
                    <div id="Results" class="container"></div>
                    <div id="Pagination"></div>
                </div>
            </div>`;

            const filters = document.getElementById('Filters');

            for (const widgetId in this.widgets) {
                const widget = this.widgets[widgetId];
                if (widget.container) {
                    const div = document.createElement('div');
                    div.id = widget.container.replace('#', '');
                    filters.appendChild(div);
                }
            }
        },

        renderSearchBox(renderOptions, isFirstRender) {
            const {query, refine, widgetParams, clear} = renderOptions;
            const container = document.querySelector(widgetParams.container);
            if (isFirstRender) {
                const input = document.createElement('input');
                const button = document.createElement('button');
                button.className = 'invisible absolute';

                input.className = 'ais-SearchBox-input';
                input.type = 'search';
                input.placeholder = 'Enter Search Terms';
                input.autocomplete = 'off';
                input.autocorrect = 'off';
                input.autocapitalize = 'off';
                input.spellcheck = 'false';
                input.maxLength = '512';

                input.addEventListener('input', (event) => {
                    refine(event.target.value);
                });

                button.addEventListener('click', () => {
                    clear();
                });

                container.appendChild(input);
                container.appendChild(button);

                if (query === '') {
                    refine(this.widgets.search.value);
                }
            }
            container.querySelector('input').value = query;
        },

        changeStateToName(stateAbbreviation) {
          const states = {
            'AL': 'Alabama',
            'AK': 'Alaska',
            'AZ': 'Arizona',
            'AR': 'Arkansas',
            'CA': 'California',
            'CO': 'Colorado',
            'CT': 'Connecticut',
            'DE': 'Delaware',
            'FL': 'Florida',
            'GA': 'Georgia',
            'HI': 'Hawaii',
            'ID': 'Idaho',
            'IL': 'Illinois',
            'IN': 'Indiana',
            'IA': 'Iowa',
            'KS': 'Kansas',
            'KY': 'Kentucky',
            'LA': 'Louisiana',
            'ME': 'Maine',
            'MD': 'Maryland',
            'MA': 'Massachusetts',
            'MI': 'Michigan',
            'MN': 'Minnesota',
            'MS': 'Mississippi',
            'MO': 'Missouri',
            'MT': 'Montana',
            'NE': 'Nebraska',
            'NV': 'Nevada',
            'NH': 'New Hampshire',
            'NJ': 'New Jersey',
            'NM': 'New Mexico',
            'NY': 'New York',
            'NC': 'North Carolina',
            'ND': 'North Dakota',
            'OH': 'Ohio',
            'OK': 'Oklahoma',
            'OR': 'Oregon',
            'PA': 'Pennsylvania',
            'RI': 'Rhode Island',
            'SC': 'South Carolina',
            'SD': 'South Dakota',
            'TN': 'Tennessee',
            'TX': 'Texas',
            'UT': 'Utah',
            'VT': 'Vermont',
            'VA': 'Virginia',
            'WA': 'Washington',
            'WV': 'West Virginia',
            'WI': 'Wisconsin',
            'WY': 'Wyoming',
            'DC': 'District of Columbia',
            'AS': 'American Samoa',
            'GU': 'Guam',
            'MP': 'Northern Mariana Islands',
            'PR': 'Puerto Rico',
            'VI': 'Virgin Islands'
          };
          if (stateAbbreviation in states) {
            return states[stateAbbreviation];
          }
            return stateAbbreviation.replace(/<[^>]*>?/gm, '');
        },

        createWidgets() {
            const widgets = [
                hits({
                    container: '#Results',
                    templates: {
                        item(hit, {html}) {
                            return window.formatSingleCaseItem(hit, html);
                        },
                    },
                }),
                stats({
                    container: '#HitStats',
                    templates: {
                        text(data, { html }) {
                            let count = '';
                            if (data.hasManyResults) {
                                count += `${data.nbHits} Businesses`;
                            } else if (data.hasOneResult) {
                                count += `1 Business`;
                            } else {
                                count += `No Businesses`;
                            }
                            return html`<span class="bold">Results:</span> <span class="">${count}</span>`;
                        },
                    },
                }),
                configure({
                    filters: this.filters,
                }),
            ];

            const preselectedRefinements = {};
            const preselectedHierarchicalRefinements = {};

            for (const widgetId in this.widgets) {
                const widget = this.widgets[widgetId];
                const that = this;

                if (widget.type === 'refinementList') {
                    if (!widget.hidden) {
                        widgets.push(createDropdown(refinementList,
                            {
                                buttonText: (options) => {
                                    const count = options.items.reduce((acc, item) => acc + item.isRefined, 0);
                                    return `${count ? ((count > 1) ? widget.buttonText.activePlural : widget.buttonText.active) : widget.buttonText.default}  ${count ? ` (${count})` : ''}`;
                                },
                                buttonClassName: 'f-ui-1 text-primary',
                            })({
                            container: widget.container,
                            attribute: widget.attribute,
                            searchable: false,
                            limit: 100,
                            templates: {
                                item(item, {html}) {
                                    const {url, label, count, isRefined} = item;
                                    return html`
                                        <a href="${url}" class="${isRefined ? 'f-ui-2' : 'f-ui-1'}" data-raw-value="${label}">
                                            <span>${that.changeStateToName(label)}</span>
                                        </a>
                                    `;
                                },
                            },
                        }));
                    }
                    if (widget.value) {
                        preselectedRefinements[widget.attribute] = widget.value.split('|');
                    }
                }

                if (widget.type === 'clearRefinements') {
                    widgets.push(clearRefinements({
                        container: widget.container,
                        templates: widget.templates,
                    }));
                }

                if (widget.type === 'searchBox') {
                    widgets.push(connectSearchBox(
                        this.renderSearchBox,
                    )({
                        container: widget.container,
                    }));
                }
            }

            if (Object.keys(preselectedRefinements).length) {
                widgets.push(configure({
                    disjunctiveFacetsRefinements: preselectedRefinements,
                }));
            }
            if (Object.keys(preselectedHierarchicalRefinements).length) {
                widgets.push(configure({
                    hierarchicalFacetsRefinements: preselectedHierarchicalRefinements,
                }));
            }

            return widgets;
        },

    },
    {
        init() {
            /* Helpers */
            window.camelCaseToHumanReadable = (camelCaseText) => {
                return camelCaseText
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, str => str.toUpperCase());
            };

            window.formatSingleCaseItem = (hit, html) => {
                const badgesTemplates = {
                    'co-100': {
                        honoree: document.getElementById('CO100HonoreeBadge').innerHTML,
                        finalist: document.getElementById('CO100FinalistBadge').innerHTML,
                        winner: document.getElementById('CO100WinnerBadge').innerHTML,
                    },
                    'atsb': {
                        honoree: document.getElementById('ATSBHonoreeBadge').innerHTML,
                        finalist: document.getElementById('ATSBFinalistBadge').innerHTML,
                        winner: document.getElementById('ATSBWinnerBadge').innerHTML,
                    },
                };

                let badgeHtml = '';
                if (!hit.Badges) {
                    return;
                }
                let category = '';
                let categoryUrl = '';
                hit.Badges.forEach(badge => {
                    if (badge.awardType !== this.awardType) {
                        return;
                    }
                    const badgeSet = badgesTemplates[badge.awardType];

                    if (badge.honoree) {
                        badgeHtml = badgeSet['honoree'];
                    }

                    if (badge.finalist) {
                        badgeHtml = badgeSet['finalist'];
                    }

                    if (badge.winner) {
                        badgeHtml = badgeSet['winner'];
                    }
                    category = badge.category;
                    categoryUrl = badge.category_url
                });

                const templateForwardArrow = document.getElementById('ForwardArrow').innerHTML;

                return `
                    <div class="overflow-hidden rounded-md shadow-md relative border-grey-50 border-1 mt-32">
                        <div class="h-[35px] w-full bg-secondary text-right flex justify-between">
                            ${html`${badgeHtml}`}
                            <a class="inline-flex items-center px-[22px] space-x-6 text-grey-200 h-[35px]" href="${hit.Full_Url}">
                                <p class="text-[14px] whitespace-nowrap">View Profile</p>
                                ${templateForwardArrow}
                            </a>
                        </div>

                        <div class="py-16 px-24">
                            <a class="f-heading-5 text-legacy-grey" href="${categoryUrl}">${category}</a>
                            <div class="flex flex-col md:flex-row leading-tight md:items-end">
                                <a class=" flex-grow" href="${hit.Full_Url}">
                                    <p class="text-[24px] text-body font-semibold">${hit.Company_Name}</p>
                                </a>
                                <div class="flex md:divide-x flex-col md:flex-row divide-grey-100 f-body text-[16px] text-grey-200 border-t md:border-t-0 border-grey-100 py-8 mt-8 md:py-0 md:gap-y-0
                    gap-y-8">
                                    ${(hit.Founders.length > 0) ? `<p class="md:pr-16">${hit.Founders[0].name}, ${hit.Founders[0].professionalTitle}</p>` : ''}
                                    
                                    <p class="md:px-16">${hit.City}, ${hit.State}</p>
                                    
                                    ${(hit.Industries.length > 0) ? `<p class="md:pl-16">${hit.Industries[0]}</p>` : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            };

            this.awardType = this.$node.dataset.awardType;
            this.awardParentId = this.$node.dataset.awardParentId;
            this.category = this.$node.dataset.category ?? null;

            this.filters = 'Badges.awardType:"' + this.awardType + '"';

            const downArrowHtml = document.getElementById('DownArrow').innerHTML;

            this.widgets = {
                search: {
                    type: 'searchBox',
                    container: '#SearchBox',
                    label: 'Business Search',
                },
                industry: {
                    type: 'refinementList',
                    container: '#IndustryList',
                    attribute: 'Industries',
                    label: 'Industry',
                    buttonText: {
                        default: 'All Industries' + downArrowHtml,
                        active: 'Industry',
                        activePlural: 'Industries',
                    },
                },
                state: {
                    type: 'refinementList',
                    container: '#StateList',
                    attribute: 'State',
                    label: 'State',
                    buttonText: {
                        default: 'All States' + downArrowHtml,
                        active: 'State',
                        activePlural: 'States',
                    },
                },
            };

            if (!this.category) {
                this.widgets.categories = {
                    type: 'refinementList',
                    container: '#CategoryList',
                    attribute: this.awardParentId + '_Categories',
                    label: 'Category',
                    buttonText: {
                        default: 'All Categories' + downArrowHtml,
                        active: 'Category',
                        activePlural: 'Categories',
                    },
                }
            } else {
                this.filters += 'AND Category_IDs: "' + this.category + '"';
            }

            this.widgets.clearRefinements = {
                type: 'clearRefinements',
                container: '#ClearRefinements',
                templates: {
                    resetLabel({hasRefinements}, {html}) {
                        if (hasRefinements) {
                            return html`<span
                                    class="ais-Dropdown-button block !bg-[#eee] !hover:bg-[#ddd] !border-[#aaa]">Clear Filters</span>`;
                        }
                        return '';
                    }
                },
            };

            let preselected = this.$node.dataset.preselected;
            if (preselected) {
                preselected = JSON.parse(preselected);
            } else {
                preselected = {};
            }

            Object.keys(preselected).forEach((key) => {
                this.widgets[key].hidden = true;
                this.widgets[key].value = preselected[key];
            });


            this.createBaseHTML();

            const searchClient = algoliasearch(
                this.$node.dataset.algoliaAppId,
                this.$node.dataset.algoliaSearchKey,
            );

            window.algoliaIndexName = this.$node.dataset.algoliaIndexName;

            const search = instantsearch({
                indexName: this.$node.dataset.algoliaIndexName,
                searchClient,
                insights: true,
                routing: false,
            });

            search.addWidgets(this.createWidgets());

            search.start();

        },
    },
);

export default awardSearch;

