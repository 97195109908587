import {createBehavior} from '@area17/a17-behaviors';

const titleBarLinks = createBehavior('titleBarLinks',
    {
        appendLinksAfterNextComponent() {
            const titleBar = this.$node;
            const nextSibling = titleBar.nextSibling;
            if(window.innerWidth <= 600) {
                if (nextSibling == null) {
                    titleBar.children[2].classList.add('hidden');
                } else {
                    this.insertAfter(nextSibling, titleBar.childNodes[2]);
                }
            }
        },
        insertAfter(referenceNode, newNode) {
            if (newNode == null) {
                return;
            }
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
        },
    },
    {
        init() {
            const repeaterNode = document.querySelector('[data-component="repeater:blocks"]');
            if (this.$node.parentNode === repeaterNode) {
                this.appendLinksAfterNextComponent();
            }
        },
    }
);

export default titleBarLinks;