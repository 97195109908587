import {createBehavior} from '@area17/a17-behaviors';

const feedContent = createBehavior('feedContent',
    {
        prefillInput() {
            const queryParam = this.getQuery() ?? 1;
            const textPaginator = document.querySelector('#current-page');
            textPaginator.value = queryParam;
        },

        getQuery() {
            let params = (new URL(document.location)).searchParams;
            return params.get('page');
        },

        scrollToAnchor() {
            document.getElementById('#scroll-here').scrollIntoView(true);
        },

        attachListeners() {
            const textPaginator = document.querySelector('#current-page');
            const self = this;

            textPaginator.addEventListener('change', function() {

                self.getInputValue();

            });

            textPaginator.addEventListener('keydown', function(e) {
                if (e.code === 'Enter') {  //checks whether the pressed key is "Enter"
                    self.getInputValue();
                }
            });

            window.addEventListener('load', (event) => {
                if (window.location.search.includes('page')) {
                    setTimeout(function() {
                        self.scrollToAnchor();
                    }, 1000);

                }
            });
        },

        getInputValue() {
            const textPaginator = document.querySelector('#current-page');
            const inputValue = textPaginator.value;
            const r = /\d+/;
            const s = document.querySelector(('.total-pages')).innerHTML;
            const totalPages = s.match(r)[0];
            if (inputValue !== '' && inputValue < parseInt(totalPages, 10)) {
                window.location.href = location.protocol + '//' + location.host + location.pathname + '?page=' + inputValue;
            }
        },
    },
    {
        init() {
            this.prefillInput();
            this.attachListeners();
        }
    }
);

export default feedContent;

