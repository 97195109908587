import {createBehavior} from '@area17/a17-behaviors';

const sizzleReel = createBehavior('sizzleReel',
    {

    },
    {
        init() {
            this.video = this.$node.querySelector('video');

            // Enable sound (unmute) after user interaction
            this.video.addEventListener('click', () => {
                this.video.muted = !this.video.muted;
            });

            // Performance optimizations
            this.video.addEventListener('loadedmetadata', ()  => {
                // Hint to the browser to start playing ASAP
                this.video.play().catch((error) => {
                    console.log('Auto-play was prevented:', error);
                    // Show a play button or other UI to encourage user interaction
                });
            });

            // Pause video when not visible to save resources
            document.addEventListener('visibilitychange', () => {
                if (document.hidden) {
                    this.video.pause();
                } else {
                    this.video.play().catch((error) => {
                        console.log('Play was prevented after visibility change:', error);
                    });
                }
            });
        }
    }
);

export default sizzleReel;
