import { createBehavior } from '@area17/a17-behaviors';

const stripeEmbed = createBehavior('stripeEmbed',
    {
        handleError(error) {
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = ''; // Clear previous errors
            messageContainer.textContent = error.message || 'An unexpected error occurred. Please try again.';
        },
    },
    {
        async init() {

            // start try catch in js
            try {
                const publicKeyResponse = await fetch(window.SITE_URL + 'connect/co-100/stripe/get-public-key', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });

                const { publicKey } = await publicKeyResponse.json();

                if (!publicKey) {
                    this.handleError('Something went wrong. Please try again.');
                    return;
                }

                const stripe = Stripe(publicKey);

                const response = await fetch(window.SITE_URL + "connect/co-100/stripe/create-checkout-session", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'},
                });
                const { clientSecret } = await response.json();

                if (!clientSecret) {
                    console.error('Error creating checkout session');
                    this.handleError();
                    return;
                }

                // Initialize Checkout
                const checkout = await stripe.initEmbeddedCheckout({
                    clientSecret,
                });
                checkout.mount('#checkout')

            } catch (error) {
                this.handleError(error);
            }
        }
    }
);

export default stripeEmbed;